import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TextField, FormControl, Button, Typography, Grid, FilledInput } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { toast } from 'react-toastify';
import MaterialTable, { MTableHeader, MTableFilterRow } from 'material-table';
import moment from 'moment'
import _ from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete';
import VideocamIcon from '@material-ui/icons/Videocam';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { CommponExport } from '../../../components/ExportReport/CommponExport'
import { GETALLSHORTCODETYPE } from '../../Utility/SortCode/SortCodeQuery';
import STOCKMASTER from '../StockMaster/StockMasterQuery';
import { UPDATESTKPER, UPDATE_STOCK_DATA } from '../StockMaster/StockMasterMutation';
import { PktNoFromLotNo } from '../../../components/DropdownSelect/PktNoFromLotNo';
import VideoAndImageViewModel from '../StockMaster/VideoAndImageViewModel'
import { Accordion, AccordionSummary, AccordionDetails, useStyles } from '../../../components/Comman/Accordian'
import { GetShortCodeData, GradingSelectComponent } from '../../../components/DropdownSelect/SelectComponents/ShortCodeSelectComponent'
import { Tofixed } from '../../../components/Comman/Tofix'
import { PrintDiv } from '../../../components/Comman/exportPdfAndExcel'
import { ExportCsv } from '../../../components/ExportReport/ExportCsv'
import ExcelPng from '../../../../Excel.png'
import '../StockMaster/stock.css'
import { CsvBuilder } from 'filefy'
import { CommonShortCodeDropdown } from '../StockMaster/CommonShortCodeDropdown/index';
import { LabPartyFilterDropdown } from '../StockMaster/LabPartyFilterDropdown/index';
import { PartyTable } from '../StockMaster/PartyTable/index';
import { useMemo } from 'react';
import { LotNoFilterOption } from '../StockMaster/LotNoFilterOption/index';
import * as XLSX from 'xlsx/xlsx.mjs';
import { GetRapeRate } from '../../../components/DropdownSelect/SelectComponents/Getraprate';
import { PRICE_LIST } from '../../Utility/RapaPortUser/RapaPortUserQuery';
import { FormatError } from '../../../components/Comman/FormatError';
import { StockMasterType } from '../../../components/Constant';

const columns = [
  { title: 'Lot No', field: 'lotNoId.lotNo', headerStyle: { minWidth: 80 }, editable: "never", },
  { title: 'Pkt No', field: 'pktNoId.pktNo', headerStyle: { minWidth: 50 }, editable: "never" },
  { title: 'Rfid', field: 'rfId', render: (rowData) => { return rowData?.rfId || "-" }, headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  { title: 'Lab', field: 'lab.partyName', headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  { title: 'Cert. Id', field: 'certificateId', headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  { title: 'CTS', field: 'cts', render: (rowData) => Tofixed(rowData?.cts || 0), headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  { title: 'Color', field: 'colorId', render: (rowData) => rowData?.colorId?.shortName || "-", headerStyle: { minWidth: 30, maxWidth: 250 }, cellStyle: { maxWidth: 250, minWidth: 30 } },
  { title: 'Shape', field: 'shapeId', render: (rowData) => rowData?.shapeId?.shortName || "-", headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  { title: 'Purity', field: 'purityId', render: (rowData) => rowData?.purityId?.shortName || "-", headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  { title: 'Cut', field: 'cutId', render: (rowData) => rowData?.cutId?.shortName || "-", headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  { title: 'Polish', field: 'polishId', render: (rowData) => rowData?.polishId?.shortName || "-", headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  { title: 'Symm', field: 'symmId', render: (rowData) => rowData?.symmId?.shortName || "-", headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  { title: 'Flu', field: 'fluId', render: (rowData) => rowData?.fluId?.shortName || "-", headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  {
    title: 'Status',
    field: 'status',
    lookup: StockMasterType,
    headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 }
  },
  { title: 'Meas.', field: 'measurment', headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  { title: 'Ratio', field: 'ratio', cellStyle: { maxWidth: 200, minWidth: 8 }, headerStyle: { maxWidth: 200, minWidth: 8 } },
  { title: 'Depth', field: 'depth', headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  { title: 'Table', field: 'table', headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  { title: 'Brown', field: 'brown', headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  { title: 'Comment', field: 'comment', cellStyle: { maxWidth: 200, minWidth: 8 }, headerStyle: { maxWidth: 200, minWidth: 8 } },
  { title: 'Grd %', field: 'gradPer', headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 } },
  {
    title: 'Rap rate', field: 'rapRate',
    render: (rowData) => {
      return Tofixed(rowData?.rapRate || 0)
    },
    headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 }
  },
  { title: 'STK %', field: 'stkPer', headerStyle: { minWidth: 107 } },
  {
    title: 'Prize Per Cts', field: 'rapAmount', headerStyle: { minWidth: 80 },
    render: (rowData) => Tofixed(rowData?.rapRate - (rowData?.rapRate * (rowData?.stkPer / 100))),
    headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 }
  },
  {
    title: 'Total Prize', field: 'netRaprate', headerStyle: { minWidth: 80 },
    render: (rowData) => Tofixed((rowData?.rapRate - (rowData?.rapRate * (rowData?.stkPer / 100))) * rowData?.cts || 0),
    headerStyle: { minWidth: 30, maxWidth: 150 }, cellStyle: { maxWidth: 150, minWidth: 30 }
  },
  // { title: 'Certi. ?', field: 'isCertified', headerStyle: { minWidth: 105 } },
]
const columns1 = [

  { title: 'Location', field: 'location', cellStyle: { minWidth: 170, textAlign: "center" } },
  { title: 'Lot No', field: 'lotNoId.lotNo', headerStyle: { minWidth: 80 } },
  { title: 'Pkt No', field: 'pktNoId.pktNo', headerStyle: { minWidth: 50 } },
  { title: 'Rfid', field: 'rfId', render: (rowData) => { return rowData?.rfId || "-" }, headerStyle: { minWidth: 30, maxWidth: 110 }, cellStyle: { maxWidth: 110, minWidth: 30 } },
  { title: 'Lab', field: 'lab.partyName', cellStyle: { maxWidth: 55, minWidth: 8 }, headerStyle: { maxWidth: 55, minWidth: 8 } },
  { title: 'Cert. Id', field: 'certificateId', headerStyle: { minWidth: 114 } },
  { title: 'CTS', field: 'cts', render: (rowData) => Tofixed(rowData?.cts || 0), cellStyle: { maxWidth: 51, minWidth: 8 }, headerStyle: { maxWidth: 51, minWidth: 8 } },
  { title: 'Color', field: 'colorId.shortName', cellStyle: { maxWidth: 51, minWidth: 8 }, headerStyle: { maxWidth: 51, minWidth: 8 } },
  { title: 'Shape', field: 'shapeId.shortName', cellStyle: { maxWidth: 70, minWidth: 8 }, headerStyle: { maxWidth: 70, minWidth: 8 } },
  { title: 'Purity', field: 'purityId.shortName', cellStyle: { maxWidth: 55, minWidth: 8 }, headerStyle: { maxWidth: 55, minWidth: 8 } },
  { title: 'Cut', field: 'cutId.shortName', cellStyle: { maxWidth: 55, minWidth: 8 }, headerStyle: { maxWidth: 55, minWidth: 8 } },
  { title: 'Polish', field: 'polishId.shortName', cellStyle: { maxWidth: 56, minWidth: 8 }, headerStyle: { maxWidth: 56, minWidth: 8 } },
  { title: 'Symm', field: 'symmId.shortName', cellStyle: { maxWidth: 57, minWidth: 8 }, headerStyle: { maxWidth: 57, minWidth: 8 } },
  { title: 'Flu', field: 'fluId.shortName', cellStyle: { maxWidth: 60, minWidth: 8 }, headerStyle: { maxWidth: 60, minWidth: 8 } },
  {
    title: 'Status', field: 'status',
    render: (rowData) => {
      if (["LAB RETURN", "APPRO RETURN", "REPAIR RETURN"].includes(rowData.status)) {
        return "ON HAND"
      } else
        return rowData.status
    }
  },
  { title: 'Meas.', field: 'measurment', cellStyle: { maxWidth: 80, minWidth: 8 }, headerStyle: { maxWidth: 80, minWidth: 8 } },
  { title: 'Depth', field: 'depth', cellStyle: { maxWidth: 55, minWidth: 8 }, headerStyle: { maxWidth: 55, minWidth: 8 } },
  { title: 'Table', field: 'table', cellStyle: { maxWidth: 51, minWidth: 8 }, headerStyle: { maxWidth: 51, minWidth: 8 } },
  {
    title: 'Brown', field: 'brown',
    // cellStyle: { maxWidth: 51, minWidth: 8 }, headerStyle: { maxWidth: 51, minWidth: 8 }
  },
  {
    title: 'Grd %',
    field: 'gradPer',
    // cellStyle: {minWidth: 100},
    // headerStyle: { minWidth: 103 }
    cellStyle: { maxWidth: 65, minWidth: 8 }, headerStyle: { maxWidth: 60, minWidth: 8 }
  },
  {
    title: 'Rap rate', field: 'rapRate',
    render: (rowData) => Tofixed(rowData?.rapRate || 0),
    cellStyle: { maxWidth: 65, minWidth: 8 }, headerStyle: { maxWidth: 60, minWidth: 8 }
  },
  { title: 'STK %', field: 'stkPer', headerStyle: { minWidth: 107 } },
  {
    title: 'Prize Per Cts', field: 'rapAmount', headerStyle: { minWidth: 80 },
    render: (rowData) => Tofixed(rowData?.rapRate - (rowData?.rapRate * (rowData?.stkPer / 100))),
  },
  {
    title: 'Total Prize', field: 'netRaprate', headerStyle: { minWidth: 80 },
    render: (rowData) => Tofixed((rowData?.rapRate - (rowData?.rapRate * (rowData?.stkPer / 100))) * rowData?.cts || 0),
  },
  { title: 'Certi. ?', field: 'isCertified', headerStyle: { minWidth: 105 } },
  {
    title: '', field: 'imageUrl'
    , cellStyle: {
      minWidth: 10,
      // paddingLeft: 10,
      // paddingRight: 10,
      textAlign: "center"
    }
  },
  {
    title: '', field: 'videoUrl',
    cellStyle: {
      minWidth: 10,
      // paddingLeft: 10,
      // paddingRight: 10,
      textAlign: "center"
    }
  },
]

const stockStatus = [
  {
    id: 1,
    name: 'ON HAND'
  },
  {
    id: 2,
    name: 'LAB ISSUE'
  },
  {
    id: 3,
    name: 'APPRO ISSUE'
  },
  {
    id: 4,
    name: 'REPAIR ISSUE'
  }
];
const stockTypes = [
  {
    id: 1,
    name: 'DOSSIER'
  },
  {
    id: 2,
    name: 'CARRATUP'
  },
  {
    id: 3,
    name: 'FCUT'
  }
];

function StockMaster() {

  const classes = useStyles();
  const tableRef = useRef();

  const [filter, setFilter] = useState({})
  const [pageSize, setPageSize] = useState(10)
  const [modalData, setModelData] = useState({ title: '', url: '', id: '', V360Url: '' })

  const [open, setOpen] = useState('')
  const [page, setPage] = useState(0)
  const [selectedRow, setSelectedRow] = useState(null);
  //   const [selectedPartyRow, setSelectedPartyRow] = useState(null);
  const [sort, setSort] = useState({ key: 'cts', type: 1 });
  const [orderBy, setOrderBy] = useState(-1);
  const [orderDirection, setOrderDirection] = useState("");
  const [emailData, setEmailData] = useState([]);
  const [stockData, setStockData] = useState([]);

  // const [parties, setParties] = useState(PartyAll());
  const [type, setType] = useState([]);
  //   const [partyName, setPartyName] = useState('');
  const [lotNo, setLotNo] = useState('');
  const [packetNo, setPacketNo] = useState('');
  const [fromCts, setFromCts] = useState('');
  const [toCts, setToCts] = useState('');
  const [status, setStatus] = useState([]);
  const [lab, setLab] = useState([]);
  const [color, setColor] = useState([]);
  const [shape, setShape] = useState([]);
  const [purity, setPurity] = useState([]);
  const [cut, setCut] = useState([]);
  const [polish, setPolish] = useState([]);
  const [symmetry, setSymm] = useState([]);
  const [flourence, setFlu] = useState([]);
  const [isChecked, setChecked] = useState(false);
  const [ischange, setischange] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pricelistData, setpricelistData] = useState([]);

  const [UpdateStkPer] = useMutation(UPDATESTKPER)
  const [UpdateStockData] = useMutation(UPDATE_STOCK_DATA)
  const [loader, setLoader] = useState(false)

  const { data, refetch, loading } = useQuery(STOCKMASTER, {
    variables: { page: page + 1, limit: pageSize, filter: JSON.stringify(filter), sort: sort },
    fetchPolicy: 'cache-and-network'
  })

  const { loading: prizeLoding, data: priceList } = useQuery(PRICE_LIST, {
    fetchPolicy: 'cache-and-network',
  })


  useEffect(() => {
    setpricelistData(priceList?.getRapaportPriceList || [])
  }, [priceList]);


  const handleExportCsv = (columns, renderData) => {
    const csvColumns = columns
      .filter(columnDef => {
        return !columnDef.hidden && columnDef.field && columnDef.export !== false;
      });

    const data = renderData.map(rowData =>
      csvColumns.map(columnDef => rowData[columnDef.field])
    );

    const builder = new CsvBuilder((`stockdata ${moment().format("DD-MM-YYYY")}`) + '.csv')
      .setDelimeter(',')
      .setColumns(csvColumns.map(columnDef => columnDef.title))
      .addRows(data)
      .exportFile();

    // toolbar.setState({ exportButtonAnchorEl: null });
    // this is the reason to pass the m-table-toolbar object
  }

  let shortCodeDatas = GetShortCodeData("LOCATION")
  const defaultPropsForLocation = {
    options: shortCodeDatas,
    getOptionLabel: (option) => option.shortName
  };

  useEffect(() => {
    let result = data?.getStockMasterData?.data || []
    setTotalCount(data?.getStockMasterData?.count || 0)
    setStockData(result)
    setischange(false)
  }, [data])

  useEffect(() => {
    let result = _.map(stockData, (d) => { if (d.tableData) d.tableData.checked = false; return d });
    setStockData(result)
    setischange(false)
  }, [ischange])


  const generateFilterString = (field, filString) => {
    let data = filter
    if (field === "date") {
      if (filString)
        data = { ...filter, [field]: moment(filString).toISOString() }
      else {
        delete filter[`${field}`]
        data = { ...filter }
      }
    } else if (filString.length === 0) {
      delete filter[`${field}`]
      data = { ...filter }
    } else {
      if (field === "currencyRate" || field === "currencyAmount" || field === "partyAmtZar" || field === "adgAmtZar") {
        filString = parseFloat(filString)
        data = { ...filter, [field]: filString }
      }
      else {
        data = { ...filter, [field]: filString }
      }
    }
    setFilter(data)
  }

  const setSorting = (colId, type) => {
    if (!type) {
      setSort({ key: 'cts', type: 1 })
      setOrderBy(-1)
      setOrderDirection("")
    } else {
      setSort({ key: columns[colId].field, type: type === 'asc' ? 1 : -1 })
      setOrderBy(colId)
      setOrderDirection(type)
    }
  }

  const getStockMasterByFilter = () => {
    let typeIds = _.map(type, 'name');
    let statusId = _.map(status, 'name');
    let labIds = _.map(lab, 'id');
    let colorIds = _.map(color, 'id');
    let shapeIds = _.map(shape, 'id');
    let purityIds = _.map(purity, 'id');
    let cutIds = _.map(cut, 'id');
    let polioshIds = _.map(polish, 'id');
    let symmIds = _.map(symmetry, 'id');
    let fluIds = _.map(flourence, 'id');

    setFilter({
      "colorId": colorIds,
      "shapeId": shapeIds,
      "purityId": purityIds,
      "polishId": polioshIds,
      "cutId": cutIds,
      "fluId": fluIds,
      "symmId": symmIds,
      "lotNo": lotNo.id,
      "pktNo": packetNo.id,
      "fromCts": fromCts,
      "toCts": toCts,
      "type": typeIds,
      "status": statusId,
      "labId": labIds
    })
  }
  const getStockMasterRefresh = () => {
    setFilter({
      "colorId": [],
      "shapeId": [],
      "purityId": [],
      "polishId": [],
      "cutId": [],
      "fluId": [],
      "symmId": [],
      "lotNo": '',
      "pktNo": '',
      "fromCts": '',
      "toCts": '',
      "type": [],
      "status": [],
      "labId": []
    })
    setStatus([])
    setType([])
    setLab([])
    setColor([])
    setShape([])
    setPurity([])
    setCut([])
    setPolish([])
    setSymm([])
    setFlu([])
    setLotNo('')
    setPacketNo('')
    setFromCts('')
    setToCts('')
  }
  // console.log("content: _.map(emailData, 'id'", _.map(emailData, 'id');

  const pdfHeader = () => {
    return <div style={{ marginBottom: 15 }}>
      <h2 style={{ textAlign: "center" }}>Stock Master</h2>

    </div>
  }
  const TableHeader = () => {
    return <>
      <tr>
        <td colSpan={columns.length}><div ><h2 style={{ textAlign: "center" }}>Stock Master</h2></div></td>
      </tr>
    </>
  }
  let PktData = PktNoFromLotNo(lotNo && lotNo.id ? lotNo.id : null)

  const modalOpenAndClose = (d, rowData, id, title, videoV360Url) => {
    // if (rowData) {
    setModelData({
      id: id,
      title: title,
      url: rowData,
      V360Url: videoV360Url
    })
    // }
    setOpen(!open);
  };
  const UpdateStock = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      let latestReqData = {}
      let colorId = newData?.colorId?.id || newData?.colorId || ""
      let cutId = newData?.cutId?.id || newData?.cutId || ""
      let fluId = newData?.fluId?.id || newData?.fluId || ""
      let inwardDetailId = newData?.inwardDetailId || ""
      let lab = newData?.lab?.id || newData?.lab || ""
      let lotNoId = newData?.lotNoId?.id || newData?.lotNoId || ""
      let polishId = newData?.polishId?.id || newData?.polishId || ""
      let purityId = newData?.purityId?.id || newData?.purityId || ""
      let pktNoId = newData?.pktNoId?.id || newData?.pktNoId || ""
      let symmId = newData?.symmId?.id || newData?.symmId || ""
      let shapeId = newData?.shapeId?.id || newData?.shapeId || ""

      if (colorId) {
        latestReqData.colorId = colorId
      }

      if (cutId) {
        latestReqData.cutId = cutId
      }

      if (fluId) {
        latestReqData.fluId = fluId
      }

      if (inwardDetailId) {
        latestReqData.inwardDetailId = inwardDetailId
      }

      if (lab) {
        latestReqData.lab = lab
      }

      if (lotNoId) {
        latestReqData.lotNoId = lotNoId
      }

      if (polishId) {
        latestReqData.polishId = polishId
      }

      if (purityId) {
        latestReqData.purityId = purityId
      }

      if (pktNoId) {
        latestReqData.pktNoId = pktNoId
      }

      if (symmId) {
        latestReqData.symmId = symmId
      }

      if (shapeId) {
        latestReqData.shapeId = shapeId
      }


      latestReqData = {
        isActive: true,
        oldData: [{ ...oldData }],
        brown: newData?.brown || '',
        certificateId: newData?.certificateId || '',
        comment: newData?.comment || '',
        cts: newData?.cts || 0,
        depth: newData?.depth || '',
        gradPer: parseFloat(newData?.gradPer || 0),
        imageUrl: newData?.imageUrl || '',
        isCertified: ["true", "false"].includes(newData?.isCertified || '') ? '' : newData?.isCertified,
        location: newData?.location || '',
        netAmount: parseFloat(newData?.netAmount || 0),
        measurment: newData?.measurment || '',
        netRaprate: parseFloat(newData?.netRaprate || 0),
        rapAmount: parseFloat(newData?.rapAmount || 0),
        rapRate: parseFloat(newData?.rapRate || 0),
        videoV360Url: newData?.videoV360Url || '',
        videoUrl: newData?.videoUrl || '',
        table: newData?.table || '',
        stkPer: parseFloat(newData?.stkPer || 0),
        status: newData?.status || '',
        rfId: newData?.rfId || '',
        ratio: newData?.ratio || '',
        id: newData.id || ''
      }
      if (newData?.issuePartyId) {
        latestReqData = {
          ...latestReqData,
          issuePartyId: newData?.issuePartyId || ""
        }
      }
      UpdateStockData({ variables: { input: { id: newData.id, ...latestReqData } } }).then((data) => {
        toast.success('Stock Update successfully.');
        setLoader(false)
        resolve()
        refetch()
      }).catch((e) => {
        setLoader(false)
        reject()
        toast.error(FormatError(e))
      })
    })
  }

  const { data: shortCodeData } = useQuery(GETALLSHORTCODETYPE, {
    variables: { type: "All" },
    fetchPolicy: 'cache-and-network',
  })

  const getShortCodeData = (TYPE) => {
    return _.filter(shortCodeData?.getAllType || [], (d) => d.type === TYPE);
  }

  const setdefaultValues = (Value) => {
    return Value?.id || Value
  }

  let SalesTable = useMemo(() => {
    let result = data?.getStockMasterData?.data || []
    if (loading) {
      return <Grid container className={classes.loader} ><img src="https://i.gifer.com/4V0b.gif" alt="loading" /></Grid>
    } else
      return <MaterialTable
        title="Stock Master"
        columns={columns}
        isLoading={loading || loader}
        tableRef={tableRef}
        data={result}
        totalCount={totalCount}
        page={page}
        options={{
          debounceInterval: 300,
          emptyRowsWhenPaging: false,
          exportAllData: true,
          columnsButton: true,
          // exportButton: true,
          exportCsv: handleExportCsv,
          exportFileName: 'stockdata.csv',
          padding: 'dense',
          pageSize: pageSize,
          pageSizeOptions: [20, 50, 100, 150, 200, 250, 400, 500, 600, 1000],
          addRowPosition: 'first',
          filtering: false,
          sorting: true,
          search: false,
          paging: true,
          selection: true,
          filterCellStyle: { padding: '5px', },
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5',
            fontSize: 14
          }),
          headerStyle: { backgroundColor: 'whitesmoke' },
        }}
        editable={{
          isEditHidden: false,
          onRowUpdate: (newData, oldData) => UpdateStock(newData, oldData),
        }}
        onChangeRowsPerPage={size => setPageSize(size)}
        onChangePage={page => { setPage(page) }}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        onSelectionChange={(rows) => {
          setEmailData(rows)
        }}
        components={{
          FilterRow: props => {
            return <MTableFilterRow
              {...props}
              columns={props.columns}
              onFilterChanged={(columnId, value) => {
                props.onFilterChanged(columnId, value);
                generateFilterString(props.columns[columnId].field, value)
              }}
            />
          },
          Header: props => {
            return <MTableHeader
              {...props}
              orderBy={orderBy}
              orderDirection={orderDirection}
              onOrderChange={(orderBy, orderDirection) => {
                props.onOrderChange(orderBy, orderDirection)
                setSorting(orderBy, orderDirection)
              }}
            />
          }
        }}
      />
  }, [data, stockData, loading, loader])

  const getRapRateonChangeCtsPurityColorShape = (props) => {
    let Data = { ...props.rowData }
    Data.cts = Data?.cts || 0
    let shape = Data?.shapeId?.id || Data?.shapeId || null
    let purity = Data?.purityId?.id || Data?.purityId || null
    let color = Data?.colorId?.id || Data?.colorId || null
    let cts = parseFloat(Tofixed(Data?.cts || null))
    if (shape && purity && color && cts) {
      let raprate = GetRapeRate(pricelistData, cts, purity, color, shape, shortCodeData)
      Data.rapRate = raprate?.rapRate || 0
      Data.rapAmt = raprate?.rapAmount || 0
    }
    props.onRowDataChange(Data)
  }

  columns.map((d, key) => {
    if (d.field === "imageUrl") {
      d.render = (rowData) => {
        return <CameraAltIcon fontSize={"small"}
          onClick={(e) => modalOpenAndClose(e, rowData?.imageUrl, rowData.id, "Image")}
          style={rowData?.imageUrl?.length !== 0 ? { color: "#3f51b5", cursor: "pointer" } : { color: "gray", cursor: "unset" }} />
      }
    }
    if (d.field === "isCertified") {
      d.render = (rowData) => {
        let isCertified = ["true", "false"].includes(rowData?.isCertified) ? '' : rowData?.isCertified
        return <CameraAltIcon fontSize={"small"}
          onClick={(e) => {
            modalOpenAndClose(e, isCertified, rowData.id, "isCertified")
          }}
          style={isCertified?.length !== 0 ? { color: "#3f51b5", cursor: "pointer" } : { color: "gray", cursor: "unset" }} />
      }
    }
    if (d.field === "videoUrl") {
      d.render = (rowData) => {
        return <VideocamIcon
          style={rowData?.videoUrl?.length !== 0 ? { color: "#3f51b5", cursor: "pointer" } : { color: "gray", cursor: "unset" }}
          fontSize={"small"}
          onClick={(e) => modalOpenAndClose(e, rowData?.videoUrl, rowData.id, "Video", rowData?.videoV360Url)}
        />
      }
    }
    if (d.field === "location") {
      d.render = (rowData) => {
        let DefaultValue = _.filter(shortCodeDatas, (newd) => newd.shortName === rowData?.location)[0]
        return <Autocomplete
          {...defaultPropsForLocation}
          disableClearable
          className="stock-location-master"
          onChange={(e, newValue) => {
            let Data = _.map(stockData, (d) => {
              if (d.id === rowData.id) {
                d.location = newValue.shortName
              }
              return d
            })
            setStockData(Data)
          }}
          value={DefaultValue}
          renderInput={(params) => <TextField className="stock-location-master" style={{ margin: 0, fontSize: 14 }}  {...params} margin="normal" />}
        />
      }
    }
    if (d.field === "stkPer") {
      d.editComponent = (props) => {
        return <FilledInput
          type="text"
          defaultValue={props.rowData?.stkPer || 0}
          className={classes.returnCtsInput}
          style={{ width: 50 }}
          onChange={(e) => {
            let Data = { ...props.rowData }
            Data.stkPer = e.target.value === "" ? 0 : parseFloat(e.target.value)
            Data.netRaprate = props?.rowData?.netRaprate + (props?.rowData?.rapRate * Data.stkPer / 100)
            Data.netAmount = props?.rowData?.netAmount + (props?.rowData?.rapAmount * Data.stkPer / 100)
            props.onRowDataChange(Data)
          }} />
      }
    }
    if (d.field === "cts") {
      d.editComponent = (props) => {
        let lotNoId = props?.rowData?.lotNoId?.id || props?.rowData?.lotNoId || '';
        let pktNoId = props?.rowData?.pktNoId?.id || props?.rowData?.pktNoId || '';
        if (lotNoId && pktNoId) {
          return <TextField defaultValue={props?.rowData?.cts || 0} onChange={(e) => {
            getRapRateonChangeCtsPurityColorShape(props)
          }} />
        } else {
          return 0
        }
      }
    }
    if (d.field === "colorId") {
      d.editComponent = (props) => {
        if (props?.rowData?.colorId || null)
          return <GradingSelectComponent
            propsData={props}
            TYPE="colorId"
            componentName="IssueReturn1"
            shortCodeData={shortCodeData}
            pricelistData={pricelistData}
            data={getShortCodeData("COLOR")}
            valueDefault={setdefaultValues(props?.rowData?.colorId) || "-"}
          />
        else
          return <GradingSelectComponent
            propsData={props}
            TYPE="colorId"
            componentName="IssueReturn1"
            shortCodeData={shortCodeData}
            pricelistData={pricelistData}
            data={getShortCodeData("COLOR")}
            valueDefault={setdefaultValues(props?.rowData?.colorId) || "-"}
          />
      }
    }
    if (d.field === "shapeId") {
      d.editComponent = (props) => {
        if (props?.rowData?.shapeId || null)
          return <GradingSelectComponent
            propsData={props}
            TYPE="shapeId"
            componentName="IssueReturn1"
            shortCodeData={shortCodeData}
            pricelistData={pricelistData}
            data={getShortCodeData("SHAPE")}
            valueDefault={setdefaultValues(props?.rowData?.shapeId) || "-"}
          />
        else
          return <GradingSelectComponent
            propsData={props}
            TYPE="shapeId"
            componentName="IssueReturn1"
            shortCodeData={shortCodeData}
            pricelistData={pricelistData}
            data={getShortCodeData("SHAPE")}
            valueDefault={setdefaultValues(props?.rowData?.shapeId) || "-"}
          />
      }
    }
    if (d.field === "purityId") {
      d.editComponent = (props) => {
        if (props?.rowData?.purityId || null)
          return <GradingSelectComponent
            shortCodeData={shortCodeData}
            pricelistData={pricelistData}
            componentName="IssueReturn1"
            propsData={props}
            TYPE="purityId"
            data={getShortCodeData("PURITY")}
            valueDefault={setdefaultValues(props?.rowData?.purityId) || "-"}
          />
        else
          return <GradingSelectComponent
            shortCodeData={shortCodeData}
            pricelistData={pricelistData}
            componentName="IssueReturn1"
            propsData={props}
            TYPE="purityId"
            data={getShortCodeData("PURITY")}
            valueDefault={setdefaultValues(props?.rowData?.purityId) || "-"}
          />
      }
    }
    if (d.field === "cutId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cutId || null)
          return <GradingSelectComponent key={key} propsData={props} TYPE="cutId" data={getShortCodeData("CUT")} valueDefault={setdefaultValues(props?.rowData?.cutId) || "-"} />
        else
          return <GradingSelectComponent key={key} propsData={props} TYPE="cutId" data={getShortCodeData("CUT")} valueDefault={setdefaultValues(props?.rowData?.cutId) || "-"} />
      }
    }
    if (d.field === "polishId") {
      d.editComponent = (props) => {
        if (props?.rowData?.polishId || null)
          return <GradingSelectComponent key={key} propsData={props} TYPE="polishId" data={getShortCodeData("POLISH")} valueDefault={setdefaultValues(props?.rowData?.polishId) || "-"} />
        else
          return <GradingSelectComponent key={key} propsData={props} TYPE="polishId" data={getShortCodeData("POLISH")} valueDefault={setdefaultValues(props?.rowData?.polishId) || "-"} />
      }
    }
    if (d.field === "symmId") {
      d.editComponent = (props) => {
        if (props?.rowData?.symmId || null)
          return <GradingSelectComponent key={key} propsData={props} TYPE="symmId" data={getShortCodeData("SYMMENTRY")} valueDefault={setdefaultValues(props?.rowData?.symmId) || "-"} />
        else
          return <GradingSelectComponent key={key} propsData={props} TYPE="symmId" data={getShortCodeData("SYMMENTRY")} valueDefault={setdefaultValues(props?.rowData?.symmId) || "-"} />
      }
    }
    if (d.field === "fluId") {
      d.editComponent = (props) => {
        if (props?.rowData?.fluId || null)
          return <GradingSelectComponent key={key} propsData={props} TYPE="fluId" data={getShortCodeData("FLOURSENCE")} valueDefault={setdefaultValues(props?.rowData?.fluId) || "-"} />
        else
          return <GradingSelectComponent key={key} propsData={props} TYPE="fluId" data={getShortCodeData("FLOURSENCE")} valueDefault={setdefaultValues(props?.rowData?.fluId) || "-"} />
      }
    }
    return null
  })

  function exportExcelFile(workbook) {
    return XLSX.writeFile(workbook, `${"Stock Master" + moment()}.xlsx`);
  }

  const exportCsvData = () => {
    var workbook = XLSX.utils.book_new();
    var worksheet_data = document.getElementById("table-to-xls");
    var worksheet = XLSX.utils.table_to_sheet(worksheet_data);
    workbook.SheetNames.push("Test");
    workbook.Sheets["Test"] = worksheet;
    exportExcelFile(workbook);
  }

  if (loading)
    return <Grid container className={classes.loader} ><img src="https://i.gifer.com/4V0b.gif" alt="loading" /></Grid>
  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="container">
            <form className={classes.root} noValidate autoComplete="off">
              <div className="column-left">
                <Grid container>
                  <Grid item xs={10}>
                    <PartyTable refetch={refetch} emailData={emailData} />
                  </Grid>
                </Grid>
                {/* <TextField id="standard-basic" label="Party Name" name="partyName" onChange={e => setPartyName(e.target.value)} />&nbsp;&nbsp; */}
              </div>
              <div className="column-center">
                <LotNoFilterOption value={lotNo} setValue={setLotNo} />
                <FormControl className={classes.formControl} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    options={PktData}
                    getOptionLabel={(option) => option.pktNo}
                    value={packetNo}
                    onChange={(event, newValue) => {
                      setPacketNo(newValue)
                    }}
                    className="lotmarginCustom"
                    renderInput={(params) => <TextField label="Packet No"  {...params} margin="normal" />}
                  />
                </FormControl>
                <TextField style={{ width: 100 }} id="standard-basic" label="From Cts" name="fromCts" onChange={e => setFromCts(e.target.value)} />&nbsp;&nbsp;
                <TextField style={{ width: 100 }} id="standard-basic" label="To Cts" name="toCts" onChange={e => setToCts(e.target.value)} />&nbsp;&nbsp;
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    multiple
                    options={stockTypes}
                    value={type}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setType(newValue)
                    }}
                    className="custom-margin-top"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Type"
                      />
                    )}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    multiple
                    options={stockStatus}
                    value={status}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setStatus(newValue)
                    }}
                    className="custom-margin-top"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Status"
                      />
                    )}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <LabPartyFilterDropdown value={lab} setValue={setLab} />
                </FormControl>
                <br></br>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: 34, marginTop: 12 }}
                  onClick={e => getStockMasterByFilter()}>Search
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => PrintDiv()}
                  style={{ height: 34, marginLeft: 5, marginTop: 12 }}
                >Print
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: 34, marginLeft: 5, marginTop: 12 }}
                  onClick={e => getStockMasterRefresh()}>Refresh
                </Button>

              </div>
              <div className="column-right">
                <FormControl className={classes.formControl}>
                  <CommonShortCodeDropdown value={color} setvalue={setColor} type={"COLOR"} label="Color" />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <CommonShortCodeDropdown label="Shape" value={shape} setvalue={setShape} type={"SHAPE"} />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <CommonShortCodeDropdown label="Purity" value={purity} setvalue={setPurity} type={"PURITY"} />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <CommonShortCodeDropdown label="Cut" value={cut} setvalue={setCut} type={"CUT"} />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <CommonShortCodeDropdown label="Polish" value={polish} setvalue={setPolish} type={"POLISH"} />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <CommonShortCodeDropdown label="Symmentery" value={symmetry} setvalue={setSymm} type={"SYMMENTRY"} />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <CommonShortCodeDropdown label="Flu." value={flourence} setvalue={setFlu} type={"FLOURSENCE"} />
                </FormControl>
              </div>
            </form>
          </div>
        </AccordionDetails>
      </Accordion>

      <Grid container>
        <VideoAndImageViewModel modalClose={modalOpenAndClose} open={open} Title="" modalData={modalData} refetch={() => refetch()} />
        <Grid item xs={12} style={{ position: "relative" }}>
          <img src={ExcelPng} alt="alternative text" title="csv" className="download-table-xls-button" onClick={() => exportCsvData()} />
          <ExportCsv
            columns={[...columns1, {
              title: 'videoV360Url', field: 'videoV360Url',
              cellStyle: {
                minWidth: 10,
                // paddingLeft: 10,
                // paddingRight: 10,
                textAlign: "center"
              }
            },]}
            TableHeader={TableHeader()}
            AllData={_.cloneDeep(stockData).filter(d => {
              if (d?.videoUrl !== "") {
                d.videoUrl = `https://drive.google.com/file/d/${d?.videoUrl}/view`;
              }
              if (d.videoV360Url !== "") {
                d.videoV360Url = d.videoV360Url;
              }
              if (d?.imageUrl !== "") {
                d.imageUrl = `https://drive.google.com/uc?export=view&id=${d.imageUrl}`
              }
              if (d?.isCertified && d?.isCertified !== "" && d?.isCertified !== "false") {
                d.isCertified = `https://drive.google.com/uc?export=view&id=${d.isCertified}`
              } else {
                d.isCertified = ""
              }
              if (d.date !== "Grand Total") {
                d.date = moment(d.date).format('DD-MM-YYYY')
                d["partyId.partyName"] = d?.partyId?.partyName
                d["brokerId.partyName"] = d?.brokerId?.partyName
                d["lotNoId.lotNo"] = d?.lotNoId?.lotNo
                d["pktNoId.pktNo"] = d?.pktNoId?.pktNo
                d["shapeId.shortName"] = d?.shapeId?.shortName
                d["colorId.shortName"] = d?.colorId?.shortName
                d["purityId.shortName"] = d?.purityId?.shortName
                d["lab.partyName"] = d?.lab?.partyName
                d["cutId.shortName"] = d?.cutId?.shortName
                d["polishId.shortName"] = d?.polishId?.shortName
                d["symmId.shortName"] = d?.symmId?.shortName
                d["fluId.shortName"] = d?.fluId?.shortName
                // d.isCertified = d.isCertified === true ? "YES" : "No"
              }
              return d
            })}

          />
          {SalesTable}
        </Grid>
      </Grid>
      <CommponExport
        columns={columns1}
        pdfHeader={pdfHeader()}
        AllData={emailData.filter(d => {
          if (d.date !== "Grand Total") {
            d.date = moment(d.date).format('DD-MM-YYYY')
            d["partyId.partyName"] = d?.partyId?.partyName
            d["brokerId.partyName"] = d?.brokerId?.partyName
            d["lotNoId.lotNo"] = d?.lotNoId?.lotNo
            d["pktNoId.pktNo"] = d?.pktNoId?.pktNo
            d["shapeId.shortName"] = d?.shapeId?.shortName
            d["colorId.shortName"] = d?.colorId?.shortName
            d["purityId.shortName"] = d?.purityId?.shortName
            d["lab.partyName"] = d?.lab?.partyName
            d["cutId.shortName"] = d?.cutId?.shortName
            d["polishId.shortName"] = d?.polishId?.shortName
            d["symmId.shortName"] = d?.symmId?.shortName
            d["fluId.shortName"] = d?.fluId?.shortName
            // d.isCertified = d.isCertified === true ? "YES" : "No"
          }
          return d
        })} />
    </div>

  );
}
export default withRouter(StockMaster)
