import gql from 'graphql-tag';

export const SEND_STOCK_EMAIL = gql`
mutation sendMailNotification($input: emailInputRequest!){
  sendMailNotification(input:$input){
    data
  }
}
`;
export const DELETE_FILE_IN_STOCK = gql`
mutation deletefilefromStockMaster($input: deleteFileFromStock){
  deletefilefromStockMaster(input:$input)
}
`;

export const UPDATESTKPER = gql`
mutation  updateStkPer($input: updationstkPer){
  updateStkPer(input:$input)
}
`;

export const UPDATE_STOCK_DATA = gql`
mutation  updateStockData($input: SalesStockSelfRefInput){
  updateStockData(input:$input)
}
`;