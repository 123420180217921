import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GETALLSHORTCODETYPE } from '../../../pages/Utility/SortCode/SortCodeQuery';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GetRapeRate } from '../../../components/DropdownSelect/SelectComponents/Getraprate'
import { Tofixed } from '../../Comman/Tofix'
import _ from 'lodash'

export const GetShortCodeData = (TYPE) => {
  const { data } = useQuery(GETALLSHORTCODETYPE, {
    variables: { type: TYPE },
    fetchPolicy: 'cache-and-network',
  })

  let ShortCodeTypenewdata = data?.getAllType || []
  return ShortCodeTypenewdata
}

export const GradingLookup = (data) => {
  let newShortCodeTypedata = {}
  data.map((d, index) => {
    newShortCodeTypedata[d.id] = d.shortName
    return null
  })
  return newShortCodeTypedata
}


export const GradingSelectComponent = (props) => {
  const changeselect = (e, newData) => {
    let Data = { ...props.propsData.rowData }
    let component = props?.componentName || ""

    Data[`${props.TYPE}`] = newData.id

    if (component === "Inward" || component === "IssueReturn" || component === "IssueReturn1") {
      let shape = Data?.shapeId || null
      let purity = Data?.purityId || null
      let color = Data?.colorId || null
      let cts = parseFloat(Tofixed(Data?.cts || null))
      if (shape && purity && color && cts && props.pricelistData) {
        let raprate = GetRapeRate(props.pricelistData, cts, purity, color, shape, props.shortCodeData)
        Data.rateRap = raprate?.rapRate || 0
        if (component === "Inward")
          Data.amountRap = raprate?.rapAmount || 0
        else if (component === "IssueReturn")
          Data.rapAmt = raprate?.rapAmount || 0
        else if(component === "IssueReturn1"){ 
          Data.rapRate = raprate?.rapRate || 0
          Data.rapAmt = raprate?.rapAmount || 0
        }
      }
    }

    props.propsData.onRowDataChange(Data)
  }
  const defaultProps = {
    options: props.data,
    getOptionLabel: (option) => option.shortName
  };

  return <Autocomplete
    {...defaultProps}
    disableClearable
    onChange={(e, newValue) => changeselect(e, newValue)}
    value={_.filter(props.data, (newd) => newd.id === props.valueDefault)[0]}
    renderInput={(params) => <TextField   {...params} margin="normal" />}
  />

}
export const GradingLocationSelectComponent = (props) => {

  const changeselect = (e, newData) => {
    let Data = { ...props.propsData.rowData }
    Data[`${props.TYPE}`] = newData.shortName
    props.propsData.onRowDataChange(Data)
  }

  const defaultProps = {
    options: props.data,
    getOptionLabel: (option) => option.shortName
  };
  let DefaultValue = _.filter(props.data, (newd) => newd.shortName === props.valueDefault)[0]
  return <Autocomplete
    {...defaultProps}
    disableClearable
    onChange={(e, newValue) => changeselect(e, newValue)}
    defaultValue={DefaultValue}
    renderInput={(params) => <TextField   {...params} margin="normal" />}
  />


}
